<template>
	<div>

		<div class="ft20 cl-black cl-main ftw500">商品管理</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="商品名称">
						<a-input v-model="search.goods_name" placeholder="请输入商品名称"></a-input>
					</a-form-item>

					<!-- <a-form-item label="销量">
						<a-input v-model="search.bg_take_count" style="width: 150px"></a-input>
						-
						<a-input v-model="search.end_take_count" style="width: 150px"></a-input>
					</a-form-item> -->

					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20">
			<div class="bg-w pd30" style="min-height: 600px;">
				<div class="flex alcenter">
					<a-button type="primary" icon="plus" @click="addGoodsAct()">添加商品</a-button>
				</div>

				<!-- <div class="mt30">
					<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
						<a-radio-button :value="1">
						  上架中
						</a-radio-button>
						<a-radio-button :value="2">
						  已售罄
						</a-radio-button>
						<a-radio-button :value="3">
						  已下架
						</a-radio-button>
					</a-radio-group>
				</div> -->


				<div class="mt30">
					<div class="wxb-table-gray">
						<a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange"
							:data-source="datas" :loading="loading">
							<!-- <div class="flex  " slot="goods" slot-scope="record">
								<div class="goods-index-cover-img">
									<img v-if="record.cover_img != null" :src="record.cover_img"/>
									<span class="tag" v-if="record.is_tui==1">首推</span>
								</div>
								<div class="ml10 flex alcenter">
									<div class="ft14 ftw500 cl-info " style="text-align: left;">
										<span class="manage-goods-item tag" v-if="record.is_open_yu==1">预售</span>
										{{record.goods_name}}
									</div>
								</div>
							</div> -->

							<!-- <div class="flex alcenter center" slot="vip_price" slot-scope="vip_price,record">
								¥{{vip_price}}
							</div>
							
							<div class="flex alcenter center" slot="original_price" slot-scope="original_price,record">
								¥{{original_price}}
							</div> -->
							<template slot="type" slot-scope="type">
								{{ type == 1 ? '优惠劵' : '充值劵' }}

							</template>

							<template slot="action" slot-scope="record">
								<div class="flex center">
									<a class="menu-act" href="javascript:;" @click="editGoodsAct(record)">
										<i class="iconfont ft14 iconedit"></i>
										<span class="ml10">编辑</span>
									</a>
									<a-popconfirm title="确定要删除该商品吗?" ok-text="确认" cancel-text="取消" @confirm="deleteGoods(record)"
										@cancel="cancleDelete">
										<a href="#" style="margin-left: 10px;">删除</a>
									</a-popconfirm>
									<!-- <a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											  <a-menu-item>
												<a class="menu-act" href="javascript:;" @click="editGoodsAct(record)">
													<i class="iconfont ft14 iconedit"></i>
													<span class="ml10">编辑</span>
												</a>
											  </a-menu-item>
											  <a-menu-item v-if="record.status==1" >
												<a class="menu-act" href="javascript:;" @click="undercarriage(record)">
													<i class="iconfont ft14 iconxiajia"></i>
													<span class="ml10">下架</span>
												</a>
											  </a-menu-item>
											  <a-menu-item v-if="record.status==-1">
													<a class="menu-act" href="javascript:;" @click="grounding(record)">
														<i class="iconfont ft14 iconshangjia"></i>
														<span class="ml10">上架</span>
													</a>
											  </a-menu-item>
										</a-menu>
									  </a-dropdown> -->
								</div>
							</template>
						</a-table>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import {
	listMixin
} from '../../common/mixin/list.js';
export default {
	mixins: [listMixin],
	data() {
		return {
			loading: false,
			pagination: {
				current: 1,
				pageSize: 10, //每页中显示10条数据
				total: 0,
			},
			search: {
				goods_name: '',
				bg_take_count: '',
				end_take_count: '',
				status: 1,
			},
			columns: [
				// mendian_arr 一维数组，支持兑换的的门店id，
				{ title: 'id', dataIndex: 'id', align: 'center', ellipsis: true },
				{ title: '商品名称', dataIndex: 'goods_name', align: 'center', ellipsis: true },
				{ title: '劵类型', dataIndex: 'type', align: 'center', ellipsis: true, scopedSlots: { customRender: 'type' } },
				{ title: '所需积分', dataIndex: 'use_integral', align: 'center', ellipsis: true },
				{ title: '满减数额', dataIndex: 'money_limit', align: 'center', ellipsis: true },
				{ title: '优惠金额', dataIndex: 'coupon_money', align: 'center', ellipsis: true },
				{ title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },

				// {title: '商品编号',dataIndex: 'goods_id',align: 'center',ellipsis: true},
				// {
				// 	title: '是否虚拟',
				// 	dataIndex: 'is_real',
				// 	align: 'center',
				// 	customRender: (text) => {
				// 		if(text==1){
				// 			return '真实'
				// 		}
				// 		if(text==2){
				// 			return '虚拟'
				// 		}
				// 	}
				// },

				// {title: '商品',key: 'goods',align: 'center',width:150,scopedSlots: {customRender: 'goods'}},
				// {title: '最低价格',dataIndex: 'original_price',align: 'center',scopedSlots: {customRender: 'original_price'}},
				// {title: '最低积分数量',dataIndex: 'use_integral',align: 'center',ellipsis: true},
				// {title: '会员价',dataIndex: 'vip_price',align: 'center',scopedSlots: {customRender: 'vip_price'}},
				// {title: '销量',dataIndex: 'take_count',align: 'center',ellipsis: true},
				// {title: '库存',dataIndex: 'quota',align: 'center',ellipsis: true},
				// {title: '添加时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
				// {title: '排序',dataIndex: 'sort',align: 'center',ellipsis: true},
				// {title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
			],
			datas: [],
		}
	},
	methods: {
		getLists() {
			if (this.loading == true) return;
			this.loading = true;
			this.$http.api('admin/goods_list', {
				// this.$http.api('admin/getGoodsList',{
				limit: this.pagination.pageSize,
				page: this.pagination.current,
				goods_name: this.search.goods_name,
				bg_take_count: this.search.bg_take_count,
				end_take_count: this.search.end_take_count,
				status: this.search.status,
			}).then(res => {
				// this.pagination.total=res.total;
				this.pagination.total = res.count;
				this.datas = res.res;
				console.log(this.datas, '6+6');
				this.loading = false;
			}).catch(res => {
				console.log(res);
				this.loading = false;
			})
		},

		// undercarriage(record){
		// 	this.$confirm({
		// 		title:'确认下架这个商品吗？',
		// 		okText:"确定",
		// 		okType:"danger",
		// 		cancelText:"取消",
		// 		onOk : ()=>{
		// 			return new Promise((resolve,reject)=>{
		// 				this.$http.api('admin/undercarriageGoods',{
		// 					goods_id:record.goods_id,
		// 				}).then(res=>{
		// 					this.$message.success('下架成功');
		// 					this.getLists();
		// 				}).catch(res=>{
		// 					console.log(res);
		// 				}).finally(()=>{
		// 					resolve();
		// 				})
		// 			})
		// 		}
		// 	})
		// },

		// grounding(record){
		// 	this.$confirm({
		// 		title:'确认上架这个商品吗？',
		// 		okText:"确定",
		// 		okType:"danger",
		// 		cancelText:"取消",
		// 		onOk : ()=>{
		// 			return new Promise((resolve,reject)=>{
		// 				this.$http.api('admin/groundingGoods',{
		// 					goods_id:record.goods_id,
		// 				}).then(res=>{
		// 					this.$message.success('上架成功');
		// 					this.getLists();
		// 				}).catch(res=>{
		// 					console.log(res);
		// 				}).finally(()=>{
		// 					resolve();
		// 				})
		// 			})
		// 		}
		// 	})
		// },

		// changeStatus(){
		// 	this.getLists();
		// },

		addGoodsAct() {
			this.$router.push('/mall/editGoods');
		},

		editGoodsAct(record) {
			// this.$router.push('/mall/editGoods?id='+record.goods_id);
			this.$router.push('/mall/editGoods?id=' + record.id);
		},
		deleteGoods(record) {
			this.$http.api('admin/del_goods', {
				id: record.id
			}).then(res => {
				// this.pagination.total=res.total;
				this.$message.success('删除成功');
				this.getLists();
			}).catch(res => {
				console.log(res);
				this.loading = false;
			})
		},
		cancleDelete() {

		},
		handleTableChange(pagination, filters, sorter) {
			this.pagination.current = pagination.current;
			this.getLists();
		},

	}
}
</script>

<style>
.manage-goods-left {
	width: 300px;
	min-height: 100vh;
}

.goods-index-table img {
	width: 80px;
	height: 60px;
}

.goods-index-cover-img {
	width: 60px;
	height: 60px;
	position: relative;
}

.goods-index-cover-img img {
	width: 60px;
	height: 60px;
}

.goods-index-cover-img .tag {
	position: absolute;
	top: 0px;
	left: 0px;
	padding: 0px 4px;
	background: #FF6600;

	font-size: 12px;
	font-weight: 500;
	color: #FFFFFF;
}

.manage-goods-item.tag {
	padding: 1px 4px;
	background: rgba(71, 114, 255, 0.1);
	border-radius: 2px;

	font-size: 12px;
	font-weight: 500;
	color: #4772FF;
}
</style>
